import { autoinject } from 'aurelia-framework';
import { saveAs } from 'file-saver';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class FileService {
  private baseUrl: string = '/api/customers/{customerId}/files';

  constructor(private httpClient: AuthHttpClient) {}

  public upload(file: any, customerId: number): Promise<any> {
    const formData = new FormData();
    formData.append('files', file, file.name ? file.name : 'files');

    const apiUrl = this.baseUrl.replace('{customerId}', customerId.toString());

    return this.httpClient
      .fetch(apiUrl, {
        method: 'POST',
        body: formData,
        headers: {},
      })
      .then((res) => {
        if (res.status < 400) {
          return res.text().then((responseText) => {
            const attachmentData = JSON.parse(responseText);
            return attachmentData;
          });
        } else {
          return false;
        }
      });
  }

  public getMetaData(id: any, customerId: number): Promise<any> {
    const apiUrl = this.baseUrl.replace('{customerId}', customerId.toString());

    return this.httpClient
      .fetch(apiUrl + '/' + id + '/metadata', {
        method: 'GET',
        headers: {},
      })
      .then((response) => {
        if (response.status < 400) {
          return response.text().then((responseText) => {
            return JSON.parse(responseText);
          });
        } else {
          return response.text().then((responseText) => {
            throw new Error('An unexpected server error occurred: ' + responseText);
          });
        }
      });
  }

  public download(id: any, customerId?: number, apiUrl?: string): Promise<string | any> {
    if (!apiUrl) {
      apiUrl = this.baseUrl.replace('{customerId}', customerId.toString()) + '/' + id;
    }

    return this.httpClient
      .fetch(apiUrl, {
        method: 'GET',
        headers: {},
      })
      .then((response) => {
        if (response.status < 400) {
          let filename = '';
          const disposition = response.headers.get('content-disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }
          return response.blob().then((blob) => {
            saveAs(blob, filename);
          });
        } else {
          return response.text().then((responseText) => {
            if (response.status == 404) {
              throw new Error(`File not found`);
            } else {
              throw new Error('An unexpected server error occurred: ' + responseText);
            }
          });
        }
      });
  }
}
