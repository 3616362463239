import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ImpregnationTypeService extends BaseApiService<Models.ImpregnationType> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, Models.ImpregnationType);
  }

  public getAllCached(): Promise<Array<Models.ImpregnationType>> {
    // if the cache is already filled, return that - if not, fill the cache and return it
    if (this.cachedObjects) {
      return Promise.resolve(this.cachedObjects);
    }

    return this.getAll('/getallwithdeleted').then((res) => {
      res = res.sort(function (a, b) {
        return a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0;
      });
      this.cachedObjects = res;
      return res;
    });
  }
}
